import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import { styled } from "@mui/system";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import CustomText from "../Components/CustomText";

const db = getFirestore(); // Firebase Firestore

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#D3B3E5",
  color: theme.palette.common.black,
  fontWeight: "bold",
  textAlign: "center",
}));

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "80vh",
  padding: "0 5%",
  marginTop: 10,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
});

const StyledTable = styled(Table)({
  minWidth: 900,
  backgroundColor: "transparent",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
}));



const ExportacionesTable = () => {
  const [exportaciones, setExportaciones] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
   // Filtrar las filas para mostrar solo las de la página actual
   const paginatedExportaciones = exportaciones.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchExportaciones = async () => {
      const querySnapshot = await getDocs(collection(db, "exportaciones"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setExportaciones(data);
    };

    fetchExportaciones();
  }, []);

  return (
    <Box sx={{ justifyContent: "center", textAlign: "center" }}>
      <CustomText variant="h4" marginTop="1%">
        Reportes
      </CustomText>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Fecha Alta</StyledTableCell>
              <StyledTableCell>PDF</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedExportaciones.map((exp) => (
              <StyledTableRow key={exp.id}>
                <TableCell align="center">
                  {new Date(exp.fechaAlta.seconds * 1000).toLocaleString()}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Ver PDF">
                    <IconButton
                      color="secondary"
                      component="a"
                      href={exp.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PictureAsPdf />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={exportaciones.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>
    </Box>
  );
};

export default ExportacionesTable;
