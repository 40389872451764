// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7zfFh3fbn5rndpIWUFR_dbMTy9oz1eDY",
  authDomain: "duran-pbe.firebaseapp.com",
  databaseURL: "https://duran-pbe-default-rtdb.firebaseio.com",
  projectId: "duran-pbe",
  storageBucket: "duran-pbe.appspot.com",
  messagingSenderId: "772521180489",
  appId: "1:772521180489:web:544f4cc0ad7cd976d0ee98",
  measurementId: "G-KZJ2PB7B4C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, storage, db, analytics, firestore };
