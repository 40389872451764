import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebaseConfig";

/**
 * Valida si el correo electrónico tiene un formato válido.
 * @param {string} email - El correo electrónico a validar.
 * @returns {boolean} - Verdadero si el correo es válido, falso de lo contrario.
 */
export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

/**
 * Envía un correo electrónico de recuperación de contraseña.
 * @param {string} email - El correo electrónico al que se enviará el correo de recuperación.
 * @returns {Promise<string>} - Mensaje de éxito o error.
 */
export const sendPasswordReset = async (email) => {
  if (!validateEmail(email)) {
    throw new Error("El formato del correo electrónico es inválido.");
  }

  try {
    await sendPasswordResetEmail(auth, email);
    return "Correo de recuperación enviado con éxito.";
  } catch (err) {
    throw new Error("Hubo un error al enviar el correo de recuperación.");
  }
};
