import { Button, styled } from "@mui/material";

/**
 * CssButtonContained component is a styled Material-UI Button with custom styles.
 * 
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @param {string} [props.colorText] - Text color of the button.
 * @param {string} [props.backgroundColor] - Background color of the button.
 * @param {string} [props.boxShadowColor] - Box shadow color of the button.
 * @param {string} [props.border] - Border color of the button on hover.
 * @param {string} [props.backgroundColorHover] - Background color of the button on hover.
 * 
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const CssButtonContained = styled(Button)(
  ({
    theme,
    colorText,
    width,
    height,
    backgroundColor,
    boxShadowColor,
    border,
    backgroundColorHover,
  }) => ({
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px 25px",
    gap: "10px",
    width: width || "60%",
    fontFamily: "Rubik, sans-serif",
    textTransform: "none",
    fontWeight: 500,
    height: height ? height : "66px",
    margin: "2%",
    fontSize: 19,
    backgroundColor: backgroundColor,
    borderRadius: "32px",
    color: colorText || theme.palette.primary,
    boxShadow: `10px 10px 37px 0px ${boxShadowColor}`,
    "&:hover": {
      border: `5px solid ${border}`,
      backgroundColor: backgroundColorHover,
      fontFamily: "Rubik, sans-serif",
      textTransform: "none",
      fontWeight: 500,
    },
  })
);
