import React, { useEffect, useState } from "react";
import { fetchExpenses, fetchHistoricalMovements } from "../APIs/fetchExpenses";
import StyledTable from "../Components/StyledTable";
import CustomText from "../Components/CustomText";

const ExpenseHistory = () => {
  const [rows, setRows] = useState([]);

  const columns = [
    // { field: "id", headerName: "ID" },
    { field: "origin", headerName: "Origen" },
    { field: "suborigin", headerName: "Suborigen" },
    { field: "department", headerName: "Departamento" },
    { field: "subdepartment", headerName: "Subdepartamento" },
    { field: "amount", headerName: "Monto" },
    { field: "paymentMethod", headerName: "Método de Pago" },
    { field: "sourceAccount", headerName: "Cuenta de Origen" },
    { field: "date", headerName: "Fecha de realización" },
    { field: "responsible", headerName: "Responsable" },
    { field: "archiveUrl", headerName: "Archivo" },
    { field: "details", headerName: "Detalles" },
    { field: "createdAt", headerName: "Fecha de Creación" },
    { field: "actions", headerName: "Opciones" }, 
  ];

  useEffect(() => {
    const getData = async () => {
      const expensesList = await fetchExpenses();
      setRows(expensesList);

      if (expensesList.length > 0) {
        const sampleExpense = expensesList[0];
        const columns = Object.keys(sampleExpense).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
        }));
        console.log(columns,"COLUMNS EXPENSES")
      }
    };
    getData();
  }, []);
  return (
    <>
      <div>
        <CustomText
          variant="h4"
          text="Histórico de gastos"
          color="primary"
          align="center"
          marginTop={3}
        >
          Histórico de gastos
        </CustomText>
        <StyledTable columns={columns} rows={rows} fetchHistoricalMovements={fetchHistoricalMovements} title="Histórico_de_gastos"/>
      </div>
    </>
  );
};

export default ExpenseHistory;
