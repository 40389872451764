import React from "react";
import { Routes, Route } from "react-router-dom";
import AddExpense from "./AddExpense";
import AddSale from "./AddSale";
import AddIncome from "./AddIncome";
import ExpenseHistory from "./ExpenseHistory";
import IncomeHistory from "./IncomeHistory";
import SalesHistory from "./SalesHistory";

const RoutesMaster = () => {
  return (
      <Routes>
        {/* FUNCIONALES */}
        <Route path="AddExpense" element={<AddExpense />} />
        <Route path="AddSale" element={<AddSale />} />
        <Route path="AddIncome" element={<AddIncome />} />
        <Route path="ExpenseHistory" element={<ExpenseHistory />} />
        <Route path="IncomeHistory" element={<IncomeHistory />} />
        <Route path="SalesHistory" element={<SalesHistory />} />
        
      </Routes>
  );
};

export default RoutesMaster;
