import React from "react";
import { useTheme } from "@mui/material";
import { CssButtonOutlined } from "../CSS/CssButtonOutlined";
import { CssButtonContained } from "../CSS/CssButtonContained";

/**
 * CustomButton component to render the CssButton with theme support.
 *
 * @param {object} props - Component props.
 * @param {string} props.type - The type of the button (e.g., "submit").
 * @param {boolean} props.fullWidth - If true, the button will take the full width of its container.
 * @param {string} props.variant - The variant of the button (e.g., "contained").
 * @param {string} props.color - The color of the button.
 * @param {object} props.sx - The style overrides for the button.
 * @param {React.ReactNode} props.children - The content of the button.
 * @returns {JSX.Element} Themed button component.
 */
export default function CustomButton({
  type,
  fullWidth,
  variant,
  color,
  colorTexto,
  sx,
  children,
}) {
  const theme = useTheme();

  // Define colors based on the theme
  const borderColor = theme.palette.mode === "dark" ? "#8796A5" : "#aab4be";
  const colorText =  theme.palette.mode === "dark" ? "#fff" : "#000000";
  const boxShadowColor =
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(0, 111, 191, 0.25)";

  return (
    <>
      {variant === "outlined" ? (
        <CssButtonOutlined
          type={type}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          borderColor={borderColor}
          boxShadowColor={boxShadowColor}
          sx={sx}
        >
          {children}
        </CssButtonOutlined>
      ) : (
        <CssButtonContained
          type={type}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          colorText={colorTexto ? colorTexto :colorText}
          borderColor={borderColor}
          boxShadowColor={boxShadowColor}
          sx={sx}
        >
          {children}
        </CssButtonContained>
      )}
    </>
  );
}
