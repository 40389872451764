import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import { PriceChange } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomText from "../Components/CustomText";
import CustomField from "../Components/CustomField";
import CustomAutocomplete from "../Components/CustomAutocomplete";
import CustomButton from "../Components/CustomButton";
import CustomPayment from "../Components/CustomPayment";
import CustomSourceAccount from "../Components/CustomSourceAccount";
import CustomOrigins from "../Components/CustomOrigins";
import {
  addDoc,
  collection,
  getFirestore,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const administrativos = [
  { label: "Sebastián Duran", id: 1 },
  { label: "Gerardo Durán", id: 2 },
  { label: "María Elena Duran", id: 3 },
  { label: "Gerardo Durán Jr.", id: 4 },
];

const departments = [
  { label: "Finance", id: 1 },
  { label: "HR", id: 2 },
  { label: "IT", id: 3 },
];

const subdepartments = [
  { label: "Accounts", id: 1, departmentId: 1 },
  { label: "Payroll", id: 2, departmentId: 2 },
  { label: "Support", id: 3, departmentId: 3 },
];

const AddExpense = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().slice(0, 10),
    origin: null,
    suborigin: null,
    amount: 0,
    paymentMethod: "",
    sourceAccount: "",
    department: null,
    subdepartment: null,
    makes: null,
    receives: null,
    details: "",
    archive: null,
  });
  const [message, setMessage] = useState({ type: "", text: "", open: false });
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "archive") {
      setFormValues((prevValues) => ({ ...prevValues, archive: files[0] }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const handleOriginChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      origin: value,
      suborigin: null,
    }));
  };

  const handleDepartmentChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      department: value,
      subdepartment: null,
    }));
  };

  const handleCustomForm = (name, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage({ type: "", text: "", open: false });
    console.log("add expense formValues", formValues);
    if (
      !formValues.date ||
      !formValues.origin ||
      !formValues.suborigin ||
      !formValues.amount ||
      !formValues.paymentMethod ||
      !formValues.department ||
      !formValues.subdepartment ||
      !formValues.details ||
      !formValues.makes ||
      !formValues.receives
    ) {
      setMessage({
        type: "warning",
        text: "Todos los campos son obligatorios",
        open: true,
      });
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        setMessage({
          type: "error",
          text: "Usuario no autenticado",
          open: true,
        });
        return;
      }

      const userRef = doc(db, "users", user.uid);
      let archiveUrl = "";

      if (formValues.archive) {
        const archiveRef = ref(storage, `archives/${formValues.archive.name}`);
        await uploadBytes(archiveRef, formValues.archive);
        archiveUrl = await getDownloadURL(archiveRef);
      }

      const expenseRef = await addDoc(collection(db, "Expenses"), {
        date: new Date(formValues.date),
        origin: formValues.origin.label,
        suborigin: formValues.suborigin.label,
        amount: formValues.amount,
        paymentMethod: formValues.paymentMethod,
        sourceAccount: formValues.sourceAccount || null,
        department: formValues.department.label,
        subdepartment: formValues.subdepartment.label,
        makes: formValues.makes,
        receives: formValues.receives,
        details: formValues.details,
        archiveUrl: archiveUrl,
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      await addDoc(collection(expenseRef, "historicalmovements"), {
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      setMessage({
        type: "success",
        text: "¡Registro exitoso!",
        open: true,
      });

      setTimeout(() => {
        navigate("/");
      }, 2000);

      console.log("handleSubmit in AddExpense", formValues);
    } catch (error) {
      console.error("Error al agregar el documento: ", error);
      setMessage({
        type: "error",
        text: "Error al registrar el gasto",
        open: true,
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        padding: 2,
      }}
    >
      <CustomText
        variant="h4"
        text="Agregar Gasto"
        color="primary"
        align="center"
      />
      <Grid container spacing={2}>
        <CustomField
          type="date"
          name="date"
          label="Fecha"
          value={formValues.date}
          onChange={handleChange}
          required
          grid={4}
          gridXS={12}
          width="96%"
        />
        <CustomOrigins
          formValues={formValues}
          handleOriginChange={handleOriginChange}
          setFormValues={setFormValues}
          grid={4}
          gridXS={12}
          width="96%"
        />
        <CustomField
          type="number"
          name="amount"
          label="Monto"
          value={formValues.amount}
          onChange={handleChange}
          required
          grid={4}
          gridXS={12}
          width="96%"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PriceChange />
              </InputAdornment>
            ),
          }}
        />
        <CustomPayment
          formValues={formValues}
          handleCustomForm={handleCustomForm}
          grid={4}
          gridXS={12}
          width="96%"
        />
        <CustomSourceAccount
          formValues={formValues}
          handleCustomForm={handleCustomForm}
          id="sourceAccount in Expense"
          name="sourceAccount"
          label="Cuenta de origen"
          grid={4}
          gridXS={12}
          width="96%"
          disabled={formValues.paymentMethod === "Efectivo" && true}
        />
        <CustomAutocomplete
          name="department"
          label="Departamento"
          grid={4}
          gridXS={12}
          width="96%"
          required={true}
          options={departments}
          onChange={handleDepartmentChange}
          value={formValues.department}
          getOptionLabel={(option) => option.label}
        />
        <CustomAutocomplete
          name="subdepartment"
          label="Subdepartamento"
          grid={4}
          gridXS={12}
          width="96%"
          required={true}
          options={subdepartments.filter(
            (subdep) => subdep.departmentId === formValues.department?.id
          )}
          onChange={(value) =>
            setFormValues((prevValues) => ({
              ...prevValues,
              subdepartment: value,
            }))
          }
          value={formValues.subdepartment}
          getOptionLabel={(option) => option.label}
        />
        <CustomAutocomplete
          name="makes"
          label="¿Quién realizo el gasto?"
          grid={4}
          gridXS={12}
          width="96%"
          required={true}
          options={administrativos}
          onChange={(value) =>
            setFormValues((prevValues) => ({ ...prevValues, makes: value.label }))
          }
          value={formValues.makes}
          getOptionLabel={(option) => option.label}
        />
        <CustomAutocomplete
          name="receives"
          label="Recibe"
          grid={4}
          gridXS={12}
          width="96%"
          required={true}
          options={administrativos}
          onChange={(value) =>
            setFormValues((prevValues) => ({ ...prevValues, receives: value.label }))
          }
          value={formValues.receives}
          getOptionLabel={(option) => option.label}
        />
        <CustomField
          type="text"
          name="details"
          label="Detalles"
          grid={4}
          gridXS={12}
          width="96%"
          value={formValues.details}
          onChange={handleChange}
          required
          multiline
          rows={4}
        />
        <CustomField
          type="file"
          name="archive"
          label="Archivo"
          grid={4}
          gridXS={12}
          width="96%"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
        />
      </Grid>
      <CustomButton type="submit" variant="contained">
        <CustomText
          component="subtitle2"
          variant="subtitle2"
          textAlign="right"
          align="right"
          justifyContent="right"
        >
          Ingreso de gasto
        </CustomText>
      </CustomButton>
      <Snackbar
        open={message.open}
        autoHideDuration={6000}
        onClose={() => setMessage({ ...message, open: false })}
      >
        <Alert
          onClose={() => setMessage({ ...message, open: false })}
          severity={message.type}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddExpense;
