import React from "react";
import { Box } from "@mui/material";
import CustomAutocomplete from "./CustomAutocomplete";

/**
 * Componente para gestionar el método de pago en un formulario.
 * @module CustomPayment
 */
const CustomPayment = ({ handleCustomForm, grid, gridXS, width }) => {
  const handlePaymentChange = (event) => {
    console.log("event", event);
    handleCustomForm("paymentMethod", event.label);
  };

  return (
    <CustomAutocomplete
      onChange={handlePaymentChange}
      name="payment"
      label="Tipo de pago"
      width={width}
      options={metodos}
      grid={grid}
      gridXS={gridXS}
      required
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <p {...props}>
          {option.icon} &nbsp;&nbsp;
          {option.label}
        </p>
      )}
    />
  );
};

export default CustomPayment;

// Opciones para el tipo de pago
const metodos = [
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Efectivo.png"
        alt="Efectivo"
        width={30}
        height={30}
      />
    ),
    label: "Efectivo",
    id: 1,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Transferencia.png"
        alt="Transferencia"
        width={30}
        height={30}
      />
    ),
    label: "Transferencia",
    id: 2,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/icono_tarjeta.png"
        alt="Tarjeta de débito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de débito",
    id: 3,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Credito.png"
        alt="Tarjeta de crédito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de crédito",
    id: 4,
  },
];
