// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

// Componente de ruta protegida
const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  console.log("isAuthenticated",isAuthenticated)
  if (!isAuthenticated) {
    // Si el usuario no está autenticado, redirigir a la página de login
    return <Navigate to="/login" />;
  }

  // Si el usuario está autenticado, renderizar los hijos (children)
  return children;
};

export default PrivateRoute;
