import React from 'react';

const Home = () =>{

    return(
        <>
        </>
    );
};

export default Home;