import React, { useState } from "react";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import CustomText from "./CustomText";
import CustomField from "./CustomField";
import CustomButton from "./CustomButton";
import { sendPasswordReset } from "../Controllers/resetPassword";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalRecoveryPassword = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ type: "", text: "", open: false });

  const handlePasswordReset = async (e) => {
    e.preventDefault(); // Previene el envío del formulario por defecto
    if (!email) {
      setMessage({
        type: "warning",
        text: "Por favor, ingrese un correo electrónico.",
        open: true,
      });
      return;
    }

    try {
      const response = await sendPasswordReset(email);
      setMessage({
        type: "success",
        text: response,
        open: true,
      });
    } catch (err) {
      setMessage({ type: "error", text: err.message, open: true });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: 800 }}
          component="form"
          onSubmit={handlePasswordReset}
        >
          <CustomText component="p" variant="h5">
            Recuperar contraseña
          </CustomText>
          <CustomField
            required
            width="100%"
            id="Correo electrónico"
            label="Correo electrónico"
            name="Correo electrónico"
            autoComplete="email"
            type="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {message.type === "error" && (
            <CustomText component="p" variant="body2" color="error.main">
              {message.text}
            </CustomText>
          )}
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            colorTexto="#fff"
            sx={{ mt: 3, mb: 3, width: "98%" }}
          >
            <CustomText
              component="subtitle2"
              variant="subtitle2"
              textAlign="right"
              align="right"
              justifyContent="right"
            >
              Enviar correo electrónico
            </CustomText>
          </CustomButton>
        </Box>
      </Modal>
      <Snackbar
        open={message.open}
        autoHideDuration={6000}
        onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
          severity={message.type}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalRecoveryPassword;
