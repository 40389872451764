import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useTheme,
  Popover,
} from "@mui/material";
import { ExitToApp, Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import logoWhite from "../assets/Logo_D_white.png"; // Importa la imagen local in white
import logo from "../assets/logo512.png"; // Importa la imagen local
import CustomText from "./CustomText";

const Navbar = () => {
  const { isAuthenticated, logout, permissions } = useAuth();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (event, menu) => {
    setSelectedSubMenu(menu);
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSelectedSubMenu(null);
    setSubMenuAnchorEl(null);
  };

  const hasPermission = (permission) => permissions.includes(permission);

  return (
    <AppBar color="lightWhite" position="sticky">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={theme.palette.mode === "dark" ? logoWhite : logo}
            width={40}
            height={40}
            sx={{ mr: 1 }}
          />
          <CustomText variant="h6" component="div" sx={{ ml: 2 }}>
            Finanzas
          </CustomText>
        </Box>
        {isAuthenticated && (
          <>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {hasPermission("generate_report") && (
                <MenuItem
                  onClick={(event) => handleSubMenuClick(event, "Gastos")}
                >
                  Gastos
                </MenuItem>
              )}
              {hasPermission("view_reports") && (
                <MenuItem
                  onClick={(event) => handleSubMenuClick(event, "Ingresos")}
                >
                  Ingresos
                </MenuItem>
              )}
              {hasPermission("view_access") && (
                <MenuItem
                  onClick={(event) => handleSubMenuClick(event, "Ventas")}
                >
                  Ventas
                </MenuItem>
              )}
            </Menu>
            <Popover
              anchorEl={subMenuAnchorEl}
              open={Boolean(subMenuAnchorEl)}
              onClose={handleSubMenuClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {selectedSubMenu === "Gastos" && (
                <>
                  <MenuItem
                    component={Link}
                    to="/Master/AddExpense"
                    onClick={handleSubMenuClose}
                  >
                    Añadir
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/ExpenseHistory"
                    onClick={handleSubMenuClose}
                  >
                    Histórico
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/ExpenseCharts"
                    onClick={handleSubMenuClose}
                  >
                    Gráficos
                  </MenuItem>
                </>
              )}
              {selectedSubMenu === "Ingresos" && (
                <>
                  <MenuItem
                    component={Link}
                    to="/Master/AddIncome"
                    onClick={handleSubMenuClose}
                  >
                    Añadir
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/IncomeHistory"
                    onClick={handleSubMenuClose}
                  >
                    Histórico
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/IncomeCharts"
                    onClick={handleSubMenuClose}
                  >
                    Gráficos
                  </MenuItem>
                </>
              )}
              {selectedSubMenu === "Ventas" && (
                <>
                  <MenuItem
                    component={Link}
                    to="/Master/AddSale"
                    onClick={handleSubMenuClose}
                  >
                    Añadir
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/SalesHistory"
                    onClick={handleSubMenuClose}
                  >
                    Histórico
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/Master/SalesCharts"
                    onClick={handleSubMenuClose}
                  >
                    Gráficos
                  </MenuItem>
                </>
              )}
            </Popover>
            <Button color="inherit" onClick={logout}>
              <ExitToApp />
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
