/**
 * Componente funcional para renderizar un autocompletado personalizado.
 * @module CustomAutocomplete
 * @param {object} props - Propiedades del componente.
 * @param {string} props.label - Etiqueta del autocompletado.
 * @param {array} props.options - Opciones disponibles para el autocompletado.
 * @param {number} props.grid - Tamaño del grid del componente.
 * @param {number} props.gridXS - Tamaño del grid XS del componente.
 * @param {function} props.onTextChange - Función manejadora del cambio de texto en el campo de entrada.
 * @param {function} props.onChange - Función manejadora del cambio de valor seleccionado.
 * @param {function} props.getOptionLabel - Función para obtener la etiqueta de la opción seleccionada.
 * @param {function} props.renderOption - Función para renderizar una opción en el autocompletado.
 * @param {string} [props.width] - Ancho del autocompletado.
 * @param {boolean} [props.required] - Indica si el autocompletado es obligatorio.
 * @returns {JSX.Element} Elemento de React que representa el autocompletado personalizado.
 */
import React from "react";
import { Grid, TextField } from "@mui/material";
import { CssAutocomplete } from "../CSS/CssAutocomplete";

const CustomAutocomplete = ({
  label,
  options,
  grid,
  gridXS,
  onTextChange,
  onChange,
  getOptionLabel,
  renderOption,
  width,
  required,
  groupBy,
  renderGroup,
  filterOptions,
  disabled
}) => {
  return (
    <Grid item xs={gridXS} md={grid} xl={grid}>
      <CssAutocomplete
        style={{
          width: width ? width : "96%",
        }}
        onChange={( _,value = null) => onChange(value)}
        disablePortal
        id={label}
        options={options}
        filterOptions={filterOptions}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            required={required}
            onChange={onTextChange}
            {...params}
            label={label}
          />
        )}
        renderGroup={renderGroup}
      />
    </Grid>
  );
};
export default CustomAutocomplete;
