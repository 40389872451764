import React, { useEffect, useState } from "react";
import { fetchIncomes, fetchHistoricalMovements } from "../APIs/fetchIncome";
import StyledTable from "../Components/StyledTable";
import CustomText from "../Components/CustomText";

const IncomeHistory = () => {
  const [rows, setRows] = useState([]);

  const columns = [
    // { field: "id", headerName: "ID" },
    { field: "origin", headerName: "Origen" },
    { field: "suborigin", headerName: "Suborigen" },
    { field: "amount", headerName: "Monto" },
    { field: "paymentMethod", headerName: "Método de Pago" },
    { field: "sourceAccount", headerName: "Cuenta de Origen" },
    { field: "date", headerName: "Fecha de realización" },
    { field: "makes", headerName: "Entrega Ingreso" },
    { field: "receives", headerName: "Recibe Ingreso" },
    { field: "responsible", headerName: "Responsable" },
    { field: "createdAt", headerName: "Fecha de Creación" },
    { field: "actions", headerName: "Opciones" }, 
  ];

  useEffect(() => {
    const getData = async () => {
      const incomesList = await fetchIncomes();
      setRows(incomesList);

      if (incomesList.length > 0) {
        const sampleIncomes = incomesList[0];
        const columns = Object.keys(sampleIncomes).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
        }));
        console.log(columns,"COLUMNS Incomes")
      }
    };
    getData();
  }, []);
  return (
    <>
      <div>
        <CustomText
          variant="h4"
          text="Histórico de Ingresos"
          color="primary"
          align="center"
          marginTop={3}
        >
          Histórico de Ingresos
        </CustomText>
        <StyledTable columns={columns} rows={rows} fetchHistoricalMovements={fetchHistoricalMovements}title="Histórico_de_Ingresos" />
      </div>
    </>
  );
};

export default IncomeHistory;
