import { Button, styled } from "@mui/material";

/**
 * CssButtonOutlined component is a styled Material-UI Button with custom styles.
 * 
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @param {string} [props.color] - Text color of the button.
 * @param {string} [props.borderColor] - Border color of the button.
 * @param {string} [props.boxShadowColor] - Box shadow color of the button.
 * 
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const CssButtonOutlined = styled(Button)(
  ({ theme, width, height, color, borderColor, boxShadowColor }) => ({
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px 25px",
    gap: "10px",
    width: width || "60%",
    fontFamily: "Rubik, sans-serif",
    textTransform: "none",
    fontWeight: 500,
    height: height ? height : "66px",
    margin: "2%",
    fontSize: 19,
    border: `5px solid ${borderColor}`,
    color: color || theme.palette.text.primary,
    borderRadius: "32px",
    textOverflow: "clip",
    whiteSpace: "normal",
    overflow: "hidden",
    lineHeight: 1,
    boxShadow: `10px 10px 37px 0px ${boxShadowColor}`,
  })
);
