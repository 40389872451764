// fetchDataIncome.js
import { db } from "../firebaseConfig";
import { collection, getDocs, getDoc } from "firebase/firestore";
import moment from "moment";
import 'moment/locale/es'; // Import the Spanish locale

// Set the locale to Spanish globally
moment.locale('es');

export const fetchIncomes = async () => {
  const incomesCollection = collection(db, "Incomes");
  const incomesSnapshot = await getDocs(incomesCollection);
  const incomesList = await Promise.all(incomesSnapshot.docs.map(async (doc) => {
    const data = doc.data();

    // Convert timestamps to local date strings with leading zeros using moment
    const createdAtFormatted = moment(data.createdAt.seconds * 1000).format("DD/MM/YYYY HH:mm:ss");
    const dateFormatted = moment(data.date.seconds * 1000).format("DD/MM/YYYY");

    // Format amount as Mexican currency
    const amountFormatted = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(data.amount);

    // Get responsible name from reference
    const responsibleDocRef = data.responsible;
    const responsibleDoc = await getDoc(responsibleDocRef);
    const responsibleName = responsibleDoc.exists() ? responsibleDoc.data().name : "Unknown";

    return {
      id: doc.id,
      ...data,
      createdAt: createdAtFormatted,
      date: dateFormatted,
      amount: amountFormatted,
      responsible: responsibleName,
    };
  }));

  // Sort expenses by createdAt in descending order
  incomesList.sort((a, b) => b.date - a.date);

  return incomesList;
};

export const fetchHistoricalMovements = async (incomesId) => {
  const historicalMovementsCollection = collection(db, `Incomes/${incomesId}/historicalmovements`);
  const historicalMovementsSnapshot = await getDocs(historicalMovementsCollection);
  const historicalMovementsList = historicalMovementsSnapshot.docs.map((doc) => doc.data());
  return historicalMovementsList;
};
