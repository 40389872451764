import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "./firebaseConfig"; // Ajusta la ruta según tu estructura
import { doc, getDoc } from "firebase/firestore"; // Importa getDoc

// Crear un contexto para la autenticación
const AuthContext = createContext();

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setIsAuthenticated(true);

        // Obtener permisos del usuario desde Firestore
        try {
          const userDoc = doc(firestore, "users", currentUser.uid); // Asegúrate de que la colección y el documento existan
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setPermissions(docSnap.data().permissions || []); // Asume que tienes un campo de permisos en tu documento de usuario
          } else {
            setPermissions([]);
          }
        } catch (error) {
          console.error("Error al obtener permisos:", error);
          setPermissions([]);
        }
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setPermissions([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem("authToken", "your-token"); // Guardar el token de autenticación
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("authToken"); // Eliminar el token de autenticación
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, permissions }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);
