import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Button,
  Box,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Edit,
  Delete,
  History,
  RequestQuote,
  FileDownload,
} from "@mui/icons-material";
import CustomText from "./CustomText";
import CustomModal from "./CustomModal";
import { origins } from "../Utils/origins"; // Icons Origin
import { metodos } from "../Utils/metodos"; // Icons PaymentMethods
import { accounts } from "../Utils/accounts"; // Icons accountsSource
import logo from "../assets/Logo.png"; // Asegúrate de actualizar la ruta al logo
import { auth } from "../firebaseConfig";

const StyledTable = ({ columns, rows, fetchHistoricalMovements, title }) => {
  const user = auth.currentUser;
  // console.log("user",user)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false); // Estado de descarga

  const handleOpenModal = (expense) => {
    setSelectedExpense(expense);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedExpense(null);
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = rows.filter((item) =>
    columns.some((column) =>
      item[column.field]
        ?.toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
    )
  );

  const handleExport = () => {
    setDownloading(true);
  
    // Crear un nuevo documento PDF en formato horizontal
    const doc = new jsPDF({
      orientation: "landscape",
    });
  
    // Añadir logo al encabezado
    doc.addImage(logo, "PNG", 10, 10, 15, 12);
  
    // Añadir título al encabezado
    doc.setFontSize(18);
    doc.text(title, 90, 20);
  
    // Agregar tabla con encabezados en español, excluyendo la columna de acciones
    const tableColumnHeaders = columns
      .filter((col) => col.field !== "actions")
      .map((col) => col.headerName);
  
    const tableRows = filteredData.map((row) =>
      columns
        .filter((col) => col.field !== "actions")
        .map((col) => {
          if (col.field === "archiveUrl") {
            return "";
          }
          return row[col.field] || "N/A";
        })
    );
  
    autoTable(doc, {
      startY: 30,
      head: [tableColumnHeaders],
      body: tableRows,
      didDrawCell: (data) => {
        // Si es la columna de "Ver documento", agregar un link
        if (
          data.column.index === columns.findIndex(
            (col) => col.field === "archiveUrl"
          )
        ) {
          const url = rows[data.row.index]["archiveUrl"];
          const { x, y } = data.cell;
          doc.textWithLink("Ver", x + 2, y + 5, { url });
        }
      },
    });
  
    // Añadir fecha y hora de impresión en el pie de página
    const printDate = new Date().toLocaleString();
    doc.setFontSize(10);
    doc.text(
      `Fecha de impresión: ${printDate}`,
      10,
      doc.internal.pageSize.height - 10
    );
  
    // Añadir responsable (nombre del usuario logueado) en el pie de página
    doc.text(
      `Responsable: ${user.email}`,
      150,
      doc.internal.pageSize.height - 10
    );
  
    doc.save(`${title}.pdf`);
    setDownloading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Simulamos la carga de datos
    }, 2000);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#D3B3E5",
    color: theme.palette.common.black,
    fontWeight: "bold",
    textAlign: "center",
    padding: "10px",
  }));

  const StyledTableContainer = styled(TableContainer)({
    maxHeight: "80vh",
    width: "98%",
    margin: 10,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    overflowX: "auto",
  });

  const StyledTableComponent = styled(Table)({
    minWidth: 900,
    backgroundColor: "transparent",
    borderCollapse: "separate",
    borderSpacing: "0 10px",
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
  }));

  const handleEdit = (id) => {
    console.log("Editando registro con ID:", id);
  };

  const handleDelete = (id) => {
    console.log("Eliminando registro con ID:", id);
  };

  const handleViewHistory = async (expense) => {
    const history = await fetchHistoricalMovements(expense.id);
    console.log(
      "Historial de movimientos para el registro con ID:",
      expense.id,
      history
    );
    handleOpenModal(expense);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Filtrar"
          variant="outlined"
          value={filter}
          onChange={handleFilterChange}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Tooltip title="Exportar">
          <IconButton
            onClick={handleExport}
            target="_blank"
            rel="noopener noreferrer"
            color="ligthPink"
          >
            {downloading ? (
              <>
                <CircularProgress size={24} />
                &nbsp;Generando
              </>
            ) : (
              <>
                <FileDownload />
                &nbsp;Exportar
              </>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh", // Altura mínima para centrar el spinner
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <StyledTableContainer component={Paper}>
            <StyledTableComponent stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field}>
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow key={row.id}>
                      {columns.map((column) => (
                        <TableCell key={column.field} sx={{ padding: "10px" }}>
                          {column.field === "archiveUrl" ? (
                            <Tooltip title="Ver documento">
                              <IconButton
                                component="a"
                                href={row[column.field]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <RequestQuote />
                              </IconButton>
                            </Tooltip>
                          ) : column.field === "origin" ? (
                            origins
                              .filter((option) => option.label === row.origin)
                              .map((option) => (
                                <Box
                                  key={option.id}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 12,
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <img
                                    src={option.icon}
                                    alt={option.label}
                                    width={40}
                                    height={40}
                                    style={{ marginRight: 2 }}
                                  />
                                  &nbsp;{option.label}
                                </Box>
                              ))
                          ) : column.field === "paymentMethod" ? (
                            metodos
                              .filter(
                                (option) => option.label === row.paymentMethod
                              )
                              .map((option) => (
                                <Box
                                  key={option.id}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 12,
                                    justifyContent: "space-around",
                                  }}
                                >
                                  {option.icon}
                                  &nbsp;{option.label}
                                </Box>
                              ))
                          ) : column.field === "sourceAccount" ? (
                            accounts
                              .filter(
                                (option) => option.label === row.sourceAccount
                              )
                              .map((option) => (
                                <Box
                                  key={option.id}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 12,
                                    justifyContent: "space-around",
                                  }}
                                >
                                  {option.icon}
                                  &nbsp;{option.label}
                                </Box>
                              ))
                          ) : column.field === "actions" ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip title="Editar">
                                <IconButton
                                  color="info"
                                  disabled
                                  onClick={() => handleEdit(row.id)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  color="error"
                                  disabled
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Historial">
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleViewHistory(row)}
                                >
                                  <History />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            row[column.field]
                          )}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </StyledTableComponent>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <CustomModal open={openModal} onClose={handleCloseModal}>
        <CustomText variant="h6">
          Movimientos históricos para la cuenta {selectedExpense?.account}
        </CustomText>
        {/* Mostrar los movimientos históricos en el modal */}
      </CustomModal>
    </Box>
  );
};

export default StyledTable;
