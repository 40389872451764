import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  IconButton,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import { PriceChange } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomText from "../Components/CustomText";
import CustomField from "../Components/CustomField";
import CustomButton from "../Components/CustomButton";
import CustomAutocomplete from "../Components/CustomAutocomplete";
import CustomPayment from "../Components/CustomPayment";
import CustomOrigins from "../Components/CustomOrigins";
import CustomSourceAccount from "../Components/CustomSourceAccount";

import {
  addDoc,
  collection,
  getFirestore,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const administrativos = [
  { label: "Sebastián Duran", id: 1 },
  { label: "Gerardo Durán", id: 2 },
  { label: "María Elena Duran", id: 3 },
  { label: "Gerardo Durán Jr.", id: 4 },
];

const AddIncome = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().slice(0, 10),
    origin: null,
    suborigin: null,
    amount: 0,
    paymentMethod: "",
    sourceAccount: "",
    makes: "",
    receives: "",
  });
  const [message, setMessage] = useState({ type: "", text: "", open: false });
  const db = getFirestore();
  const auth = getAuth();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleOriginChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      origin: value,
      suborigin: null,
    }));
  };

  const handleCustomForm = (name, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage({ type: "", text: "", open: false });
    console.log("add income formValues", formValues);
    if (
      !formValues.date ||
      !formValues.origin ||
      !formValues.suborigin ||
      !formValues.amount ||
      !formValues.paymentMethod ||
      !formValues.receives ||
      !formValues.makes
    ) {
      setMessage({
        type: "error",
        text: "Todos los campos son obligatorios",
        open: true,
      });
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        setMessage({
          type: "error",
          text: "Usuario no autenticado",
          open: true,
        });
        return;
      }

      const userRef = doc(db, "users", user.uid);

      const incomeRef = await addDoc(collection(db, "Incomes"), {
        date: new Date(formValues.date),
        origin: formValues.origin.label,
        suborigin: formValues.suborigin.label,
        amount: formValues.amount,
        paymentMethod: formValues.paymentMethod,
        sourceAccount: formValues.sourceAccount || null,
        makes: formValues.makes,
        receives: formValues.receives,
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      await addDoc(collection(incomeRef, "historicalmovements"), {
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      setMessage({
        type: "success",
        text: "¡Registro exitoso!",
        open: true,
      });

      setTimeout(() => {
        navigate("/");
      }, 2000);

      console.log("handleSubmit in AddIncome:", formValues);
    } catch (error) {
      setMessage({ type: "error", text: error.message, open: true });
      console.error("Error handleSubmit in AddIncome:", error);
    }
  };

  return (
    <>
      <Box sx={{ justifyContent: "center", textAlign: "center" }}>
        <CustomText variant="h4" marginTop="1%">
          Registro ingreso
        </CustomText>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container>
            <CustomField
              required
              width="96%"
              grid={4}
              gridXS={6}
              id="Fecha de ingreso"
              label="Fecha en que se realiza"
              name="date"
              autoComplete="date"
              type="date"
              autoFocus
              value={formValues.date}
              onChange={handleChange}
            />
            <CustomOrigins
              formValues={formValues}
              handleOriginChange={handleOriginChange}
              setFormValues={setFormValues}
              grid={4}
              gridXS={12}
              width="96%"
            />
            <CustomField
              required
              width="96%"
              grid={4}
              gridXS={6}
              id="amount in ingreso"
              label="Monto total"
              name="amount"
              autoComplete="numeric"
              type="number"
              value={formValues.amount}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="icon price">
                      <PriceChange />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomPayment
              grid={4}
              gridXS={6}
              width="96%"
              handleCustomForm={handleCustomForm}
            />
            <CustomSourceAccount
              formValues={formValues}
              handleCustomForm={handleCustomForm}
              grid={4}
              gridXS={12}
              width="96%"
              id="sourceAccount in ingreso"
              label="Cuenta de origen"
              name="sourceAccount"
              disabled={formValues.paymentMethod === "Efectivo" && true}
            />
            <CustomAutocomplete
              name="make"
              label="Entrega"
              grid={6}
              gridXS={12}
              width="96%"
              required={true}
              options={administrativos}
              onChange={(value) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  makes: value.label,
                }))
              }
              value={formValues.make}
              getOptionLabel={(option) => option.label}
            />
            <CustomAutocomplete
              name="receives"
              label="Recibe"
              grid={6}
              gridXS={12}
              width="96%"
              required={true}
              options={administrativos}
              onChange={(value) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  receives: value.label,
                }))
              }
              value={formValues.receives}
              getOptionLabel={(option) => option.label}
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color="info"
              colorTexto="#fff"
              sx={{ mt: 3, mb: 3, width: "98%" }}
              onClick={handleSubmit}
            >
              <CustomText
                component="subtitle2"
                variant="subtitle2"
                textAlign="right"
                align="right"
                justifyContent="right"
              >
                Registro de ingreso
              </CustomText>
            </CustomButton>
          </Grid>
        </Box>
        <Snackbar
          open={message.open}
          autoHideDuration={6000}
          onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
        >
          <Alert
            onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default AddIncome;
