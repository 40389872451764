import React from "react";
import { Paper } from "@mui/material";

/**
 * CustomCard component that wraps children components inside a Paper.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the card.
 * @param {string|number} [props.width] - The width of the card, either in percentage or pixels.
 * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl' | false} [props.maxWidth] - The maximum width of the Card.
 * @param {boolean} [props.blur] - Whether to apply a blur effect to the card.
 * @returns {JSX.Element} The rendered CustomCard component.
 */
const CustomCard = ({ children, width, padding, blur,border }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: width ? width : "80%",
        padding: padding ? padding : 4,
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 5,
        backgroundColor: blur ? "rgba(255, 255, 255, 0.4)" : "white",
        backdropFilter: blur ? "blur(10px)" : "none",
        border: border ? border : "none",
      }}
    >
      {children}
    </Paper>
  );
};

export default CustomCard;
