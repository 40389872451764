import React from "react";
import { Typography } from "@mui/material";

/**
 * CustomText component to render text with customizable styles.
 *
 * @param {Object} props - The properties object.
 * @param {React.ElementType} props.component - The component to render the Typography as (e.g., 'span', 'div').
 * @param {'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2'} props.variant - The typography variant to use.
 * @param {React.ReactNode} props.children - The content of the Typography.
 * @param {'inherit' | 'left' | 'center' | 'right' | 'justify'} [props.textAlign] - The alignment of the text.
 * @param {string} [props.textDecoration] - The decoration of the text (e.g., 'underline', 'line-through').
 * @param {string} [props.textDecorationColor] - The color of the text decoration.
 * @param {'none' | 'capitalize' | 'uppercase' | 'lowercase'} [props.textTransform] - The transformation of the text.
 * @returns {React.Element} The CustomText component.
 */
const CustomText = ({
  component,
  variant,
  children,
  align,
  textAlign,
  textDecoration,
  textDecorationColor,
  textTransform,
  justifyContent,
  marginLeft,
  color,
  marginTop,
  onClick,
}) => {
  return (
    <Typography
      onClick={onClick}
      align={align}
      sx={{
        textAlign: textAlign ? textAlign : "center",
        textDecoration: textDecoration ? textDecoration : "none",
        textDecorationColor: textDecorationColor ? textDecorationColor : null,
        textTransform: textTransform ? textTransform : "none",
        fontFamily: "Rubik, sans-serif",
        justifyContent: justifyContent ? justifyContent : "center",
        marginLeft: marginLeft ? marginLeft : 0,
        color: color && color,
        marginTop: marginTop && marginTop,
      }}
      component={component}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default CustomText;
