import { Box } from "@mui/material";

export const metodos = [
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Efectivo.png"
        alt="Efectivo"
        width={30}
        height={30}
      />
    ),
    label: "Efectivo",
    id: 1,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Transferencia.png"
        alt="Transferencia"
        width={30}
        height={30}
      />
    ),
    label: "Transferencia",
    id: 2,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/icono_tarjeta.png"
        alt="Tarjeta de débito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de débito",
    id: 3,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Credito.png"
        alt="Tarjeta de crédito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de crédito",
    id: 4,
  },
];
