// AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Views/Home";
import Login from "./Views/Login";
import RoutesMaster from "./01_Master/routes_Master";
import RoutesEmployee from "./02_Employee/routes_Employee";
import PrivateRoute from "./PrivateRoute";

/**
 * Componente que define las rutas de la aplicación.
 * @returns {JSX.Element} Elemento que renderiza las rutas definidas.
 */
const AppRoutes = () => {
  return (
    <Routes>
      {/* Ruta principal que lleva a la página de inicio */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      {/* Ruta para la página de inicio de sesión */}
      <Route path="/login" element={<Login />} />
      {/* Rutas protegidas para el módulo de facturas */}
      <Route
        path="/Master/*"
        element={
          <PrivateRoute>
            <RoutesMaster />
          </PrivateRoute>
        }
      />
      {/* Rutas protegidas para el módulo de ingresos */}
      <Route
        path="/Employee/*"
        element={
          <PrivateRoute>
            <RoutesEmployee />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
