import React, { useEffect, useState } from "react";
import { fetchSales, fetchHistoricalMovements } from "../APIs/fetchSales";
import StyledTable from "../Components/StyledTable";
import CustomText from "../Components/CustomText";

const SalesHistory = () => {
  const [rows, setRows] = useState([]);

  const columns = [
    // { field: "id", headerName: "ID" },
    { field: "origin", headerName: "Origen" },
    { field: "suborigin", headerName: "Suborigen" },
    { field: "amount", headerName: "Monto" },
    { field: "paymentMethod", headerName: "Método de Pago" },
    { field: "sourceAccount", headerName: "Cuenta de Origen" },
    { field: "date", headerName: "Fecha de realización" },
    { field: "responsible", headerName: "Responsable" },
    { field: "createdAt", headerName: "Fecha de Creación" },
    { field: "actions", headerName: "Opciones" }, 
  ];

  useEffect(() => {
    const getData = async () => {
      const salesList = await fetchSales();
      setRows(salesList);

      if (salesList.length > 0) {
        const sampleSales = salesList[0];
        const columns = Object.keys(sampleSales).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
        }));
        console.log(columns,"COLUMNS SALES")
      }
    };
    getData();
  }, []);
  return (
    <>
      <div>
        <CustomText
          variant="h4"
          text="Histórico de Ventas"
          color="primary"
          align="center"
          marginTop={3}
        >
          Histórico de Ventas
        </CustomText>
        <StyledTable columns={columns} rows={rows} fetchHistoricalMovements={fetchHistoricalMovements}  title="Histórico_de_Ventas"/>
      </div>
    </>
  );
};

export default SalesHistory;
