import React, { useState } from "react";
import {
  Box,
  Avatar,
  useTheme,
  InputAdornment,
  IconButton,
  Alert,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomField from "../Components/CustomField";
import CustomCard from "../Components/CustomCard";
import CustomText from "../Components/CustomText";
import CustomButton from "../Components/CustomButton";
import ModalRecoveryPassword from "../Components/ModalRecoveryPassword";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getDeviceModel } from "../Utils/getDeviceModel";
import { useAuth } from "../AuthContext"; // Ajusta la ruta según tu estructura

const Login = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { login } = useAuth(); // Usa el hook useAuth
  const [modalOpen, setModalOpen] = useState(false); //modal open state

  const handleOpenModal = () => {
    // function open modal
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    // function close modal
    setModalOpen(false);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [message, setMessage] = useState({ type: "", text: "", open: false });
  const db = getFirestore();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  /**
   * Maneja el cambio de valor en los campos del formulario.
   * @param {React.ChangeEvent<HTMLInputElement>} event - El evento de cambio de valor.
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  /**
   * Maneja el envío del formulario de inicio de sesión.
   * @param {React.FormEvent} event - El evento de envío del formulario.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage({ type: "", text: "", open: false });

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formValues.email,
        formValues.password
      );
      const user = userCredential.user;

      // Registrar inicio de sesión en la subcolección "logins" dentro del documento del usuario
      const loginData = {
        loginTime: new Date(),
        deviceModel: getDeviceModel(),
      };

      await addDoc(collection(db, `users/${user.uid}/logins`), loginData);

      // Actualiza el estado de autenticación
      login(); // Usa el método login del contexto

      // Mostrar alerta de éxito
      setMessage({
        type: "success",
        text: "¡Inicio de sesión exitoso!",
        open: true,
      });

      // Redirigir a otra página después de un breve retraso para mostrar el mensaje de éxito
      setTimeout(() => {
        navigate("/"); // redirigir
      }, 2000);

      console.log("Logged in as:", user);
    } catch (error) {
      let errorMessage = "";
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Correo electrónico no válido.";
          break;
        case "auth/user-disabled":
          errorMessage = "Usuario deshabilitado.";
          break;
        case "auth/user-not-found":
          errorMessage = "Usuario no encontrado.";
          break;
        case "auth/wrong-password":
          errorMessage = "Contraseña incorrecta.";
          break;
        case "auth/invalid-credential":
          errorMessage = "Verifica tus datos para el inicio de sesión.";
          break;
        default:
          errorMessage = "Error al iniciar sesión.";
      }
      setMessage({ type: "error", text: errorMessage, open: true });
      console.error("Error logging in:", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        backgroundImage:
          theme.palette.mode === "dark"
            ? `url(http://movicaremx.com/IMG/OHF/dedos_dark.png)`
            : `url(http://movicaremx.com/IMG/OHF/dedos.jpg)`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomCard
        width={isSmallScreen ? "90%" : "40%"}
        blur
        border="1px solid #2e4666"
        sx={{
          padding: isSmallScreen ? "16px" : "32px",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "info.main", width: 60, height: 60 }}>
          <LockOutlined />
        </Avatar>
        <CustomText component="h1" variant="h2">
          Inicio de sesión
        </CustomText>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <CustomField
            required
            width="100%"
            id="Correo electrónico"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            value={formValues.email}
            onChange={handleChange}
          />
          <CustomField
            required
            width="100%"
            name="password"
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            id="Contraseña"
            autoComplete="current-password"
            value={formValues.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {message.type === "error" && (
            <CustomText component="p" variant="body2" color="error.main">
              {message.text}
            </CustomText>
          )}
          <CustomText
            component="subtitle2"
            variant="subtitle2"
            marginLeft="40%"
            onClick={handleOpenModal}
          >
            Recuperar contraseña
          </CustomText>
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            colorTexto="#fff"
            sx={{ mt: 3, mb: 3, width: "98%" }}
            onClick={handleSubmit}
          >
            <CustomText
              component="subtitle2"
              variant="subtitle2"
              textAlign="right"
              align="right"
              justifyContent="right"
            >
              Iniciar sesión
            </CustomText>
          </CustomButton>
        </Box>
      </CustomCard>
      <Snackbar
        open={message.open}
        autoHideDuration={6000}
        onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
          severity={message.type}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>
      <ModalRecoveryPassword
        open={modalOpen}
        handleClose={handleCloseModal}
      />
    </Box>
  );
};

export default Login;
