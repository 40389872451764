import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  IconButton,
  Alert,
  Grid,
  Snackbar,
} from "@mui/material";
import { PriceChange } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  getFirestore,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import CustomText from "../Components/CustomText";
import CustomField from "../Components/CustomField";
import CustomButton from "../Components/CustomButton";
import CustomSourceAccount from "../Components/CustomSourceAccount";
import CustomPayment from "../Components/CustomPayment";
import CustomOrigins from "../Components/CustomOrigins";

const AddSale = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    date: new Date().toISOString().slice(0, 10),
    origin: null,
    suborigin: null,
    amount: 0,
    paymentMethod: "",
    sourceAccount: null,
  });
  const [message, setMessage] = useState({ type: "", text: "", open: false });
  const db = getFirestore();
  const auth = getAuth();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleOriginChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      origin: value,
      suborigin: null,
    }));
  };

  const handleCustomForm = (name, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage({ type: "", text: "", open: false });

    if (
      !formValues.date ||
      !formValues.origin ||
      !formValues.suborigin ||
      !formValues.amount ||
      !formValues.paymentMethod
    ) {
      setMessage({
        type: "warning",
        text: "Todos los campos son obligatorios",
        open: true,
      });
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        setMessage({
          type: "error",
          text: "Usuario no autenticado",
          open: true,
        });
        return;
      }

      const userRef = doc(db, "users", user.uid);

      const salesRef = await addDoc(collection(db, "Sales"), {
        date: new Date(formValues.date),
        origin: formValues.origin.label,
        suborigin: formValues.suborigin.label,
        amount: formValues.amount,
        sourceAccount: formValues.sourceAccount || null,
        paymentMethod: formValues.paymentMethod,
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      await addDoc(collection(salesRef, "historicalmovements"), {
        responsible: userRef,
        createdAt: serverTimestamp(),
      });

      setMessage({
        type: "success",
        text: "¡Registro exitoso!",
        open: true,
      });

      setTimeout(() => {
        navigate("/");
      }, 2000);

      console.log("handleSubmit in AddSale:", formValues);
    } catch (error) {
      setMessage({ type: "error", text: error.message, open: true });
      console.error("Error handleSubmit in AddSale:", error);
    }
  };

  return (
    <>
      <Box sx={{ justifyContent: "center", textAlign: "center" }}>
        <CustomText variant="h4" marginTop="1%">
          Ingresar venta
        </CustomText>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container>
            <CustomField
              required
              width="96%"
              grid={4}
              gridXS={6}
              id="Fecha de venta"
              label="Fecha en que se realiza"
              name="date"
              autoComplete="date"
              type="date"
              autoFocus
              value={formValues.date}
              onChange={handleChange}
            />
            <CustomOrigins
              formValues={formValues}
              handleOriginChange={handleOriginChange}
              setFormValues={setFormValues}
              grid={4}
              gridXS={12}
              width="96%"
            />
            <CustomField
              required
              width="96%"
              id="amount in venta"
              label="Monto total"
              name="amount"
              autoComplete="numeric"
              type="number"
              grid={4}
              gridXS={6}
              value={formValues.amount}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="icon price">
                      <PriceChange />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomPayment
              handleCustomForm={handleCustomForm}
              grid={4}
              gridXS={6}
              width="97%"
            />
            <CustomSourceAccount
              formValues={formValues}
              handleCustomForm={handleCustomForm}
              id="sourceAccount in sale"
              name="sourceAccount"
              label="Cuenta de origen"
              grid={4}
              gridXS={12}
              width="96%"
              disabled={formValues.paymentMethod === "Efectivo" && true}
            />
          </Grid>
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            colorTexto="#fff"
            sx={{ mt: 3, mb: 3, width: "97%" }}
            onClick={handleSubmit}
          >
            <CustomText
              component="subtitle2"
              variant="subtitle2"
              textAlign="right"
              align="right"
              justifyContent="right"
            >
              Registro de venta
            </CustomText>
          </CustomButton>
        </Box>
        <Snackbar
          open={message.open}
          autoHideDuration={6000}
          onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
        >
          <Alert
            onClose={() => setMessage((prev) => ({ ...prev, open: false }))}
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default AddSale;
