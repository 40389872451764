import React, { useState, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { esES } from "@mui/material/locale";
import AppRoutes from "./routes";
import ThemeSwitch from "./Components/ThemeSwitch";
import Navbar from "./Components/NavBar"; //Barra de navegación
import { useAuth } from "./AuthContext"; // Importa el hook useAuth

// Definición de tema claro
const lightTheme = {
  palette: {
    mode: "light",
    primary: {
      main: "#89c75b",
      light: "#acd98c",
      dark: "#356c2d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#7faec9",
      main: "#5b8fb5",
      dark: "#2e4666",
      contrastText: "#000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#000",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c20",
      contrastText: "#000",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    ligthPink: {
      light: "#ff77a9",
      main: "#ff4081",
      dark: "#c60055",
      contrastText: "#fff",
    },
    ligthYellow: {
      light: "#ffe57f",
      main: "#ffd740",
      dark: "#c6a700",
      contrastText: "#000",
    },
    moderatePurple: {
      light: "#a982d2",
      main: "#7e57c2",
      dark: "#4a0072",
      contrastText: "#fff",
    },
    lightWhite: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000",
    },
    grayish: {
      light: "#b0bec5",
      main: "#78909c",
      dark: "#546e7a",
      contrastText: "#fff",
    },
    darkBlue: {
      light: "#b1d4e5",
      main: "rgb(7, 67, 107)",
      dark: "#002d3b",
      contrastText: "#fff",
    },
  },
  esES,
};

// Definición de tema oscuro
const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#89c75b",
      light: "#acd98c",
      dark: "#356c2d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#7faec9",
      main: "#5b8fb5",
      dark: "#2e4666",
      contrastText: "#000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#000",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c20",
      contrastText: "#000",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    ligthPink: {
      light: "#c60055",
      main: "#ff4081",
      dark: "#a00037",
      contrastText: "#fff",
    },
    ligthYellow: {
      light: "#c6a700",
      main: "#ffd740",
      dark: "#a68300",
      contrastText: "#000",
    },
    moderatePurple: {
      light: "#5e35b1",
      main: "#7e57c2",
      dark: "#4a0072",
      contrastText: "#fff",
    },
    lightWhite: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000",
    },
    grayish: {
      light: "#b0bec5",
      main: "#78909c",
      dark: "#546e7a",
      contrastText: "#fff",
    },
    darkBlue: {
      light: "#b1d4e5",
      main: "rgb(7, 67, 107)",
      dark: "#002d3b",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(224, 224, 224, 1)",
          padding: "10px",
        },
      },
    },
  },
  esES,
};


function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { isAuthenticated } = useAuth(); // Obtén el estado de autenticación

  // Crear el tema basado en el modo oscuro
  const theme = useMemo(
    () => createTheme(darkMode ? darkTheme : lightTheme),
    [darkMode]
  );

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Mostrar Navbar solo si el usuario está autenticado */}
      {isAuthenticated && <Navbar />}
      <AppRoutes />
      {/* Switch para alternar entre temas en la esquina inferior derecha */}
      <ThemeSwitch
        checked={darkMode}
        onChange={handleThemeChange}
        name="theme"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      />
    </ThemeProvider>
  );
}

export default App;
