import React from "react";
import { Box } from "@mui/material";
import CustomAutocomplete from "../Components/CustomAutocomplete";

const CustomOrigins = ({
  formValues,
  handleOriginChange,
  setFormValues,
  grid,
  gridXS,
  width,
}) => {
  const allSuborigins = [
    { label: "Torre Médica", id: 1, originId: 1 },
    { label: "Urgencias", id: 2, originId: 1 },
    { label: "Hospitalización", id: 3, originId: 1 },
    { label: "Farmacia", id: 4, originId: 1 },
    { label: "LCD MTZ", id: 5, originId: 2 },
    { label: "LCD LREYES", id: 6, originId: 2 },
    { label: "LCD SVC", id: 7, originId: 2 },
    { label: "LCD HF", id: 8, originId: 2 },
    { label: "LCD TPX", id: 9, originId: 2 },
    { label: "LCD CAM", id: 10, originId: 2 },
    { label: "LCD CHALCO", id: 11, originId: 2 },
  ];

  const origins = [
    {
      label: "Operadora Hospital Futura",
      id: 1,
      icon: "https://movicaremx.com/IMG/OHF/HF.png",
    },
    {
      label: "Laboratorio Clínico Durán",
      id: 2,
      icon: "https://movicaremx.com/IMG/OHF/DULAB.png",
    },
  ];

  const filteredSuborigins = allSuborigins.filter(
    (suborigin) => suborigin.originId === formValues.origin?.id
  );

  return (
    <>
      <CustomAutocomplete
        onChange={handleOriginChange}
        name="origin"
        label="Unidad de negocio"
        required={true}
        width={width}
        options={origins}
        grid={grid}
        gridXS={gridXS}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <img src={option.icon} alt={option.label} width={48} height={48} />
            &nbsp;&nbsp;{option.label}
          </Box>
        )}
      />
      <CustomAutocomplete
        onChange={(value) =>
          setFormValues((prevValues) => ({
            ...prevValues,
            suborigin: value,
          }))
        }
        name="suborigin"
        label="Subunidad"
        required={true}
        getOptionLabel={(option) => option.label}
        options={filteredSuborigins}
        width={width}
        grid={grid}
        gridXS={gridXS}
      />
    </>
  );
};

export default CustomOrigins;
