import React from "react";
import { Box } from "@mui/material";
import CustomAutocomplete from "./CustomAutocomplete";

/**
 * Componente para gestionar la cuenta de origen en un formulario.
 * @module CustomSourceAccount
 */
const CustomSourceAccount = ({ handleCustomForm, grid, gridXS, width,disabled,id,name,label }) => {
  const handleSourceAccountChange = (event) => {
    handleCustomForm("sourceAccount", event.label);
  };

  return (
    <CustomAutocomplete
      onChange={handleSourceAccountChange}
      id={id}
      name={name}
      label={label}
      disabled={disabled}
      width={width}
      options={accounts}
      grid={grid}
      gridXS={gridXS}
      required={true}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <p {...props}>
          {option.icon} &nbsp;&nbsp;
          {option.label}
        </p>
      )}
    />
  );
};

export default CustomSourceAccount;

// Opciones para cuentas de origen
const accounts = [
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Santander.png"
        alt="Santander"
        width={64}
        height={11}
      />
    ),
    label: "Santander 6600",
    id: 1,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Santander.png"
        alt="Santander"
        width={64}
        height={11}
      />
    ),
    label: "Santander 2506",
    id: 2,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/HSBC.png"
        alt="HSBC"
        width={64}
        height={17.1}
      />
    ),
    label: "HSBC 1234",
    id: 3,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/HSBC.png"
        alt="HSBC"
        width={64}
        height={17.1}
      />
    ),
    label: "HSBC 0987",
    id: 4,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/MIFEL.png"
        alt="MIFEL"
        width={64}
        height={10.4}
      />
    ),
    label: "MIFEL 1369",
    id: 5,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/MIFEL.png"
        alt="MIFEL"
        width={64}
        height={10.4}
      />
    ),
    label: "MIFEL 2468",
    id: 6,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/American_Express.png"
        alt="American Express"
        width={57.5}
        height={20.2}
      />
    ),
    label: "American Express 3579",
    id: 6,
  },
];
