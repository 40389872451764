/**
 * Componente funcional para renderizar un campo de texto personalizado.
 * @module CustomTextField
 * @param {object} props - Propiedades del componente.
 * @param {string} props.label - Etiqueta del campo de texto.
 * @param {string} [props.type="text"] - Tipo de entrada del campo de texto.
 * @param {boolean} [props.required=true] - Indica si el campo es obligatorio.
 * @param {number} [props.grid=12] - Tamaño del grid del componente.
 * @param {string} [props.width] - Ancho del campo de texto.
 * @param {string} props.value - Valor del campo de texto.
 * @param {string} props.name - Nombre del campo de texto.
 * @param {number} [props.multiline] - Número de líneas si el campo es de varias líneas.
 * @param {function} props.onChange - Función manejadora del cambio de valor del campo.
 * @param {object} [props.InputProps] - Propiedades adicionales para el componente de entrada.
 * @param {object} [props.InputLabelProps] - Propiedades adicionales para la etiqueta del campo.
 * @param {number} [props.maxLength] - Longitud máxima permitida para el campo de texto.
 * @param {boolean} [props.disabled] - Indica si el campo está deshabilitado.
 * @param {object} [props.searchPxRef] - Referencia para el campo de búsqueda.
 * @param {function} [props.onKeyPress] - Función manejadora del evento de presionar tecla.
 * @returns {JSX.Element} Elemento de React que representa el campo de texto personalizado.
 */
import React from "react";
import { Grid } from "@mui/material";
import { CssTextField } from "../CSS/CssTextField";

const CustomField = ({
  label,
  type = "text",
  required = true,
  gridXS,
  grid,
  width,
  height,
  value,
  name,
  multiline,
  onChange,
  InputProps,
  InputLabelProps,
  maxLength,
  disabled,
  searchPxRef,
  onKeyPress,
  onKeyDown,
  helperText,
  multi,
  maxDate,
  minDate,
}) => {
  return (
    <Grid item xs={gridXS} md={grid} xl={grid}>
      <CssTextField
        ref={searchPxRef}
        style={{
          width: width ? width : "98%",
          height: height ? height : "98%",
        }}
        label={label}
        id={name}
        type={type}
        variant="outlined"
        required={required}
        value={value}
        onChange={onChange}
        name={name}
        multiline={multi}
        rows={multiline}
        InputProps={InputProps}
        InputLabelProps={{
          shrink: InputLabelProps,
        }}
        disabled={disabled}
        inputProps={{
          maxLength: maxLength,
          max: maxDate, //type date
          min: minDate, //type date
        }}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        helperText={helperText}
      />
    </Grid>
  );
};
export default CustomField;
