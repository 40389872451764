export const origins = [
    {
      label: "Operadora Hospital Futura",
      id: 1,
      icon: "https://movicaremx.com/IMG/OHF/HF.png",
    },
    {
      label: "Laboratorio Clínico Durán",
      id: 2,
      icon: "https://movicaremx.com/IMG/OHF/DULAB.png",
    },
  ];
  